<template>
    <div class="main-cotainer">
        <div class="row">
            <div class="col-md-6 p-3 py-5 order-2 order-md-1">

                <div class="form-group first">
                    <label for="username" class="required">Your Pharmacy Name</label>
                    <input name="username" type="text" class="form-control" v-model="username" placeholder="" id="" required>

                </div>
                <div class="form-group last mb-3">
                    <label for="email" class="required">Your Email</label> <span class="mb-4 " style="margin-left: 52%;"></span>
                    <input name="email" type="email" class="form-control" v-model="email" placeholder="" id="" required>

                </div>
                <div class="form-group last mb-3">
                    <label for="phone" class="required">Your Phone(+91)</label>
                    <input name="phone" type="phone" class="form-control" v-model="phone" placeholder="" id="" required>

                </div>
                <!-- <div class="form-group last mb-3">
                    <label for="phone">Remarks</label>
                    <textarea name="remark"  class="form-control" v-model="remark" placeholder="" id=""  textarea/>

                </div> -->
                <input v-on:click="Submit" type="submit" value="I need these tips now!" class="btn btn-common" style="margin-left: 25%;"/>
                <p class="text-red mb-0" style="color: #000;margin-left: 20%;font-size: small;" >EBook pdf will reach you by Whatsapp</p>
               

            </div>
            <div class="col-md-6 text-center order-1 order-md-2">
                <div class="img-half"><img src="images/eBook.png" /></div>
                <p class="text-red mb-0" style="color: #AF0A0C;font-size:large;" ><b>Unlock the Secrets to Grow Pharmacy Sales!</b></p>
                <p class="text-red mb-0" style="color: #000;" >100 proven tips for pharmacy stores in India!</p>
                <p class="text-red mb-0" style="color: #AF0A0C;" >Get this ebook Free Today!</p>
            </div>
        </div>
    </div>
   
</template>

<script >
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
 
export default {
        name: 'QuickReg',
        data() {
            return {
                username: '',
                email: '',
                phone: '',
            }
        },
        methods: {
            async Submit() {
				axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
				axios.defaults.headers.post['Accept'] = 'application/json';
                const submit_data = {
                    name: this.username,
                    email: this.email,
                    phone: this.phone,
                    
                }
               
                let result = await axios.post("/app/EbookSalesGrowth/eyJhbGciOiJIUz786iIsInR5cCI6IkpXVCJ9", submit_data);
                if (result.data.status == true) {
                    window.open('https://api.whatsapp.com/send?phone=918882702218&text=Hi!', '_blank');
                    window.open('https://web.whatsapp.com/send?phone=918882702218&text=Hi!', '_blank');
                    this.$router.push('/Thanks');
                } else {
                    alert(result.data.message);
                }
                
            }
        }
    }
</script>